<template>
    <v-container>
      <v-row>
        <v-col cols=12>
          <v-card
            elevation="2"
          >
            <v-card-title>
              Formulir {{buttonEdit==false ? 'Entry ': 'Ubah '}} Data Master Pengaturan Iklan
            </v-card-title>
              <form
              ref="form"
              >
                <v-container>
              <v-text-field
                v-model="formData.title"
                :error-messages="formErrors.title"
                :counter="100"
                label="Judul Model Iklan"
                required
              ></v-text-field>
              <v-text-field
                v-model="formData.total"
                :error-messages="formErrors.total"
                :counter="100"
                label="Total Model Iklan"
                required
              ></v-text-field>
              <v-text-field v-if="buttonEdit" v-show="false"
                v-model="formData.key_id"
                :error-messages="formErrors.key_id"
                required
              ></v-text-field>
  
              <v-btn v-if='!buttonEdit'
                class="mr-4"
                @click="submit"
                tile
                color="primary"
                :loading="memuat"
                :disabled="memuat"
              ><v-icon left>
                  mdi-content-save
                </v-icon>
                tambah
              </v-btn>
              <v-btn v-if='buttonEdit'
                class="mr-4"
                @click="submit"
                tile
                color="warning"
                :loading="memuat"
                :disabled="memuat"
              ><v-icon left>
                  mdi-pencil
                </v-icon>
                simpan
              </v-btn>
              <v-btn @click="batalEdit" v-if='buttonEdit'
                tile
                color="success"
              >
                batal
              </v-btn>
              <v-btn @click="clear" v-if='!buttonEdit'>
                reset
              </v-btn>
                </v-container>
            </form>
            <!-- Start Snackbar -->
            <v-container>
              <div class="text-center">
                <v-snackbar
                  v-model="snackbar"
                  :multi-line="multiLine"
                >
                  {{message}} 
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="red"
                      text
                      v-bind="attrs"
                      @click="snackbar = false"
                    >
                      Keluar
                    </v-btn>
                  </template>
                </v-snackbar>
              </div>
            </v-container>  
            <!-- End Snackbar -->
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols='12'>
          <v-card>
            <v-card-title>
              Data Master Pengaturan Iklan
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
            </v-card-title>
            <v-container>
              <v-data-table
                :headers="headers"
                :items="categories"
                :options.sync="options"
                :server-items-length="totalCategories"
                :loading="loading"
                :search="search"
                :footer-props="{
                  'items-per-page-options' : [ 5, 10, 15, 100 ]
                }"
                class="elevation-1"
              >
              <template v-slot:top>
                  <v-dialog v-model="dialogEdit" max-width="500px">
                    <v-card>
                      <v-card-title class="headline">Apakah anda yakin ingin mengubah data ?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeEdit">Batal</v-btn>
                        <v-btn color="blue darken-1" text @click="editItemConfirm">Ubah</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="520px">
                    <v-card>
                      <v-card-title class="headline">Apakah anda yakin ingin menghapus data ?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Batal</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">Hapus</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                >
                  Reset
                </v-btn>
              </template>
              </v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  <script>
    // import { validationMixin } from 'vuelidate'
    // import { required, maxLength,minLength, email } from 'vuelidate/lib/validators'
    import axios from 'axios'
    
    export default {
      // mixins: [validationMixin],
  
      // validations: {
      //   title: { required, maxLength: maxLength(100) ,minLength: minLength(3)},
      //   email: { required, email },
      //   select: { required },
      //   checkbox: {
      //     checked (val) {
      //       return val
      //     },
      //   },
      // },
      data () {
        return {
          baseUrl : 'https://api.awakkerja.com/public/',
          //start snackbar
          multiLine: true, 
          snackbar : false, 
          message : false, 
          //end snackbar
  
          //start datatable
          search  : '', 
          categories : [], 
          totalCategories : 0, 
          loading: true, 
          options: {}, 
          headers: [ 
            {
              text: '#',
              align: 'start',
              sortable: false,
              value: 'no',
            },
            { text: 'Title', value: 'title' },
            { 
              text: 'Total', 
              value: 'total', 
              sortable : false
            },
            { 
              text: 'Jumlah Iklan yg Ditetapkan', 
              value: 'count', 
              sortable : false
            },
            { 
              text: 'Aksi', 
              value:'actions',
              sortable : false
            }
          ],
          dialogDelete: false, 
          dialogEdit  : false,
          //end datatable
  
          //start form
          rules: [
            value => !value || value.size < 200000 || 'Gambar tidak boleh lebih dari 200 KB!',
          ],
          // title: '', 
          // key_id : '',
          // image   : '',
          buttonEdit : false,
          // idMasterKategoriErrors : '',
          // imageErrors: '',
          formErrors : {},
          formData : {
            title : '',
            key_id : '',
            total : '',
          },
          memuat : false,
          statusImage : false,
          tes : null,
          // email: '',
          // select: null,
          // items: [
          //   'Item 1',
          //   'Item 2',
          //   'Item 3',
          //   'Item 4',
          // ],
          // checkbox: false,
        }
      },
      computed : {
        
      },
      watch: {
        //Start Datatable
        options: {
          handler () {
            this.setApiData()
          },
          deep: true,
        },
        search : {
          handler(){
            this.setApiData()
            this.options.itemsPerPage = 10
            this.options.page = 1
          },
          deep : true,
        },
        dialogEdit (val){
          val || this.closeEdit()
        },
        dialogDelete (val) {
          val || this.closeDelete()
        },
        //End Datatable
      },
      mounted () {
        //Start Datatable
        this.setApiData()
        //End Datatable
        this.checkLogin()
      },
      methods: {
        checkLogin(){
          //check Login
          let vm = this
          setTimeout(function(){
            axios({
              method : 'get',
              url  : vm.baseUrl+'Api/UD/Auth/checkToken',
              headers : {
                'Authorization' : vm.$session.get('jwt')
              }
            }).then(function(response){
              if(response.data.status!==200){
                vm.$session.destroy()
                vm.$router.push('/')
              }
            })
          },1000)
          //end check login
        },
        submit () {
          this.memuat = true
          this.message = ''
          let form_data = new FormData()
          for(const key in this.formData){
            if(key == 'key_id'){
              if(this.buttonEdit){
                form_data.append('key_id',this.formData['key_id'])
              }
            }else{
              form_data.append(key,this.formData[key]) 
            } 
          }
          let vm = this
          axios({
            method: 'post',
            url: vm.baseUrl+'Api/UD/masterSettingsAds/save',
            data: form_data,
            headers : {
                'Authorization' : vm.$session.get('jwt'),
                'Content-Type': 'multipart/form-data'
              },
          })
          .then(function (response) {
            vm.snackbar = true
            vm.message = response.data.message
            if(response.data.status){
              vm.memuat = false
              // window.fileInputForm.reset();
              vm.clear()
              vm.setApiData()
              vm.buttonEdit = false
              // vm.formErrors = {}
            }else{
              vm.memuat = false
              vm.formErrors = response.data.formErrors
            }
          }) 
        },
        clear () {
  
          for(let k in this.formData){
            this.formData[k] = null
          }
        },
        batalEdit (){
          this.title = ''
          this.formData.key_id = ''
          this.buttonEdit = false
        },
        //end form
  
        //start table
        setApiData (){
          this.loading = true
          this.getData().then(response=>{
            this.categories = response.data.rows
            this.totalCategories = response.data.totalRows
            this.loading = false
          })
        },
        getData (){
          return new Promise((resolve,reject)=>{
  
            let vm = this;
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            axios({
              method: 'get',
              url: vm.baseUrl+'Api/UD/masterSettingsAds',
              headers : {
                'Authorization' : vm.$session.get('jwt')
              },
              params: {
                sortBy       : sortBy,
                sortDesc     : sortDesc,
                page         : page,
                itemsPerPage : itemsPerPage,
                search       : (this.search!='')?this.search:false
              }
            })
            .then(function (response) {
              if(response.status===200){
                resolve(response)
              }else{
                reject('Terjadi Kesalahan, Hubungi programmer')
              }
            })
          })
        },
        editItem (item) {
          this.formData.key_id = item.actions
          this.dialogEdit = true
        },
        editItemConfirm(){
          let vm = this
          axios({
            method : 'get',
            url    : vm.baseUrl+'Api/UD/masterSettingsAds/edit/'+vm.formData.key_id,
            headers : {
              'Authorization' : vm.$session.get('jwt')
            },
          }).then(function(response){
            vm.formData = response.data
            vm.buttonEdit = true
          })
          vm.closeEdit()
        },
        deleteItem (item) {
          this.formData.key_id = item.actions
          this.dialogDelete = true
        },
        deleteItemConfirm () {
          let vm = this
          axios({
            method : 'delete',
            url    : vm.baseUrl+'Api/UD/masterSettingsAds/delete/'+vm.formData.key_id,
            headers : {
                'Authorization' : vm.$session.get('jwt')
            },
          }).then(function(response){
            if(response.status==200){
              vm.snackbar = true
              vm.message = response.data.message
              vm.setApiData()
            }else{
              vm.snackbar = true
            }
          })
          this.closeDelete()
        },
        closeEdit (){
          this.clear()
          this.dialogEdit = false
        },
        closeDelete () {
          this.dialogDelete = false
        },
        initialize (){
          this.search = false
        }
      },
    }
  </script>